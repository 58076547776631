/**
 * Created by guanyj on  12/13/19
 * @desc 牧场管理模块的所有HTTP请求服务。
 */
import { Injectable } from "@angular/core";
import { HttpService, RequestMsg, ResponseMsg } from "@orchid_service/http";
import { URL_CONST } from "../../../shared/const/url-const";
import { Version } from "../../../shared/enum/version.enum";
import { Observable } from "rxjs";
import { GridChangeEvent, GridModel, FilterOperatorDescriptor } from "@orchid_component/grid";
import { RanchModel } from "../../../shared/model/ranch.model";
import { map } from "rxjs/operators";

@Injectable()
export class FeceiptsService {

    constructor(
        private $http: HttpService
    ) { }

    /**
     * 查询业务单据列表数据
     * @param status
     * @param name
     */
    public queryFeceiptList(query: GridChangeEvent): Observable<ResponseMsg> {
        const request = new RequestMsg();
        // const url="/orchid-pms-operator/plan/list"
        const url = "/orchid-pms-operator/plan/list/bill";
        request.url = url;
        request.body = query;
        request.version = Version.v1;
        return this.$http.post(request);
    }
    /**
     * 查询业务单据文件数据
     * @param status
     * @param name
     */
    public queryFeceiptFile(query: any): Observable<ResponseMsg> {
        const request = new RequestMsg();
        const url = "/orchid-pms-operator/plan/pdf/info";
        // request.url = URL_CONST.pastureland.livestock.list;
        request.url = url;
        request.body = query;
        request.version = Version.v1;
        return this.$http.post(request);
    }
}
