import {Injectable} from '@angular/core';
import {StorageService} from '@orchid_service/storage/src/service/storage.service';

@Injectable()
export class PlanStoreService {


    constructor(
        private $store: StorageService
    ) {}


    /**
     * 下拉牧场列表
     * @param list
     */
    set dropdownRanchList(list: any[]) {
        this.$store.set('plan.ranchName', list);
    }

    get dropdownRanchList() {
        return this.$store.get('plan.ranchName') || [];

    }

    /**
     * 列表过滤之后的牧场
     * @param list
     */
    set filterRanchList(list: any[]) {

        this.$store.set('plan.filterRanchName', list);
    }

    get filterRanchList() {
        return this.$store.get('plan.filterRanchName') || [];
    }



}
