import {Injectable} from '@angular/core';
import {FilterOperatorDescriptor, GridChangeEvent} from '@orchid_component/grid';
import {HttpService, RequestMsg, ResponseMsg} from '@orchid_service/http';
import {URL_CONST} from '../../../shared/const/url-const';
import {Version} from '../../../shared/enum/version.enum';
import {query} from '@angular/animations';
import { map } from 'rxjs/operators';
import {PlanStoreService} from './plan-store.service';
import {Observable} from 'rxjs';
import {PLATFORM_SERVER_ID} from '@angular/common/src/platform_id';
import {StorageService} from '@orchid_service/storage/src/service/storage.service';

@Injectable()
export class PlanService {
    cache = false;
    ranchList: any[];

    constructor(
        private $http: HttpService,
        private $store: PlanStoreService,
        private $storage: StorageService
    ) {

    }

    /**
     * @description 查询入栏计划列表数据
     * @param query
     */
    queryLairageList(query: GridChangeEvent) {
        const request = new RequestMsg();

        request.url = URL_CONST.plan.lairage.list;
        request.body = query;
        request.version = Version.v1;

        return this.$http.post(request);
    }

    clear() {
        this.cache = false;
        this.$storage.remove('plan.filterRanchName');
        this.$storage.remove('plan.ranchName');
    }

    /**
     * @description 查询下拉牧场列表
     */
    queryLairageRanchListByApi() {
        if (this.cache) {
            return Observable.create(obser => {
                obser.next({
                    code: 200,
                    data: this.ranchList,
                    msg: ''
                });
                obser.complete();
            });
        }
        const request = new RequestMsg();
        request.url = URL_CONST.plan.lairage.ranchList;
        request.version = Version.v3;
        request.body = {};
        return this.$http.post(request).pipe( map(result => {
            this.cache = true;
            this.ranchList = result.data;
            return result;
        }));
    }

    /**
     * @description 查询列表牧场列表
     */
    queryLairageFilterRanchListByApi(): Observable<any> {
        if (this.cache) {
            return Observable.create(obser => {
                obser.next({
                    code: 200,
                    data: this.ranchList,
                    msg: ''
                });
                obser.complete();
            });
        }
        const request = new RequestMsg();
        request.url = URL_CONST.plan.lairage.ranchList;
        request.version = Version.v2;
        request.body = {};
        return this.$http.post(request).pipe( map(result => {
            this.cache = true;
            this.ranchList = result.data;
            return result;
        }));
    }

    /**
     * @description 上传文件
     * @param ranchId string  牧场id
     * @param file 二进制
     */
    upFilesByApi(ranchId: string, file: any, type = "inbound") {
        const request = new RequestMsg();
        request.url = URL_CONST.plan.uploadFile;
        request.version = Version.v1;
        request.body = {
            ranchId, file, type
        };
        return this.$http.uploadFile(request);
    }

    /**
     * @description 创建入栏、出栏的提交
     * @param data Object
     */
    commitInstallRanchByApi(data) {
        const request = new RequestMsg();
        request.url = URL_CONST.plan.createPlan.commitInstallRanch;
        request.version = Version.v1;
        request.body = data;
        return this.$http.post(request);
    }

    /**
     * @description 入栏详情上半部分
     * @param data Object
     */
    installRanchDetailPrevByApi(data) {
        const request = new RequestMsg();
        request.url = URL_CONST.plan.lairage.detail.prev;
        request.version = Version.v1;
        request.body = data;
        return this.$http.post(request);

    }

    /**
     * @description 入栏详情下半部分
     * @param data Object
     */
    installRanchDetailNextByApi(data) {
        const request = new RequestMsg();
        request.url = URL_CONST.plan.lairage.detail.next;
        request.version = Version.v1;
        request.body = data;
        return this.$http.post(request);
    }


    /**
     * @description 入栏、出栏记录
     * @param data
     */
    planRecordByApi(data) {
        const request = new RequestMsg();
        request.url = URL_CONST.plan.installRecord;
        request.version = Version.v1;
        request.body = data;
        return this.$http.post(request);
    }

}
