/**
 * Created by guanyj on  12/16/19
 */

export enum LivestockStatusEnum {

    /** 已出栏 */
    passed = 0,

    /** 已入栏 */
    lairaged = 1,

    /** 已存栏 */
    livestocked = 3,

    /** 可出栏 */
    slaughter = 2
}
